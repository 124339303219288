
*{
  box-sizing: border-box ;
}
.bold_text{
  font-weight: 700;
}
.right_text{
  text-align: right;
}
/* Header  */
.navbar_container{
  position:fixed;
  width:100%;
  z-index: 1;
}
.navbar_box{
  /* position:fixed */
  display:flex;
  justify-content: space-between;
  margin-top:20px;
  padding-bottom: 10px;
  
  /* border-bottom: 1px solid var(--color-line-gray); */
}
.navbar_logo_container{
  display:flex;
  flex-direction: column;
  margin-left: 20px;
}
.navbar_logo_title{
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.7px;
  /* color:var(--color-white); */
}
.navbar_logo_subtext{
  font-size: 0.8rem;
  letter-spacing: 5px;
  margin-top:-5px;
  margin-left:2px;
  color:rgb(68, 68, 68);
}
.navbar_items{
  display:flex;
  justify-content: space-around;
  margin-right:20px;

}
.navbar_item{
  padding:5px 10px;
  margin : auto 0;
}
.navbar_item.contact{
  background-color: var(--color-white);
  border-radius: 20px;
  -webkit-box-shadow: 14px 13px 31px -24px rgba(0,0,0,0.75);
  -moz-box-shadow: 14px 13px 31px -24px rgba(0,0,0,0.75);
  box-shadow: var(--shadow-1);
}

.navbar_item.contact::after{
 
  content:"무료상담";
 
  background-color:var(--color-red);
  color:var(--color-white);
  padding:5px 10px;
  border-radius: 30px;
  font-size:.8rem;
  font-weight: 700;
  position:relative;
  top:-10px;
  left:5px

}
.navbar_item:hover{
  color:gray;
}
/* .red_bubble{
  position:absolute;
  top:13px;
  right: 7px;
  background-color:var(--color-red);
  color:var(--color-white);
  padding:5px 10px;
  border-radius: 30px;
  font-size:.7rem;
  font-weight: 700;
 
} */

/* Section */

/* .section{
  min-height: 400px;
} */
.section.first{
/* background: rgb(126,122,185); */
background: var(--gradient-main);
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
height:400px;
}
/* .section_first_img_container{
  position: relative;
} */
.section_first_img{
  margin-top:20px;
  width:250px;
  animation: rotate 5s infinite alternate ease-in-out; /* 회전 애니메이션 적용 */
  transform-style: preserve-3d; /* 3D 변환을 유지 */
  filter: brightness(1);
}
.section_first_text{
  margin: 10px auto;
  font-size: 0.8rem;
  font-weight: 300;

}
/* 대문 로고 로테이션 효과 */
@keyframes rotate {
  from {
    transform: perspective(350px) rotateY(-10deg);
  }
  to {
    transform: perspective(350px) rotateY(10deg);
  }
}

/* Shape Divider */
.custom-shape-divider-bottom-1721098286 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1721098286 svg {
  position: relative;
  display: block;
  width: calc(216% + 1.3px);
  height: 42px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1721098286 .shape-fill {
  fill: #FFFFFF;
}

/* Section Second */
.section.second{
  background-color:white;
  font-family: 'noto sans';
  font-weight: 300;
  color:var(--color-gray);
  max-width: 500px;
  margin:40px auto;
  margin-bottom: 80px;
  padding:0 10px;

}

.section_second_p{
  margin-top: 30px;
  margin-bottom: 30px;
  /* overflow-wrap:break-word; */
}



/* Section third */

.section.third{
  background-color:white;
}
.section_third_img_container{
  margin-top:0;
  height: 200px;
  /* width:100vw; */
  background-image: url("/public/section_3_img1.jpg");
  background-size:contain; /* 배경 이미지가 컨테이너에 맞게 조정됨 */
  background-repeat: no-repeat; /* 배경 이미지가 반복되지 않음 */
}

/* Section 4 */
.section_4_container{
  max-width: 1000px;
  margin:0 auto;
}
.section_4_title{
  font-size: 1.7rem;;
  font-family: 'noto sans';
  font-weight: 400;
  text-align: center;
  border-bottom: var(--dotted-line);
  color:var(--color-black);
  border-radius: 15px 15px 0  0;
  padding-bottom: 10px;
  margin:0 20px;
  /* background-color:var(--color-gray-bright); */
  /* background: var(--gradient-main); */
  
}
.section_4_lists{
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  border-bottom: var(--dotted-line);
  
}
.section_4_list{
  text-align: center;
}
.section_4_h4{
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-line-gray);
}
.section_4_list_item{
  width:100%;
  color:var(--color-gray);
}
/* g2g projects section */
.section_g2g_projects{
  position: relative;
}

.g2g_projects_h1{
  
  font-size: 1.7rem;;
  font-family: 'noto sans';
  font-weight: 400;
  text-align: center;
  border-bottom: var(--dotted-line);
  color:var(--color-black);
  border-radius: 15px 15px 0  0;
  padding-bottom: 20px;
  margin: 30px 0;
}
.g2g_projects_container{
  overflow: hidden;
  border-bottom: var(--dotted-line);
  margin-bottom:50px;
}

.g2g_projects_box{
  position:relative;
  
  padding:30px 30px;
  display:flex;
  justify-content: space-between;
  gap:20px;
  white-space: nowrap;
  
  overflow-x: auto;
  scroll-behavior: smooth;
}

.scroll_button_container{
  position:absolute;
  width:100vw;
 top:50%;
 
}
.scroll_button{
   color:white;
  margin:0 20px;
  font-size: 7rem;
  font-weight: 900;
  opacity: 30%;
  cursor:pointer;
  z-index: 1;
}
.scroll_button:hover{
  opacity:90%;
  transition:all 0.5s ease;
}

.scroll_button_box{
  display:flex;
  justify-content: space-between;
  align-items: center;
}


/* Card Box */

.card_container{
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex: 0 0 auto;
  background-color: var(--color-white);
  border-radius: 15px;
  width:300px;
  height:370px;
  box-shadow: var(--shadow-2);
  position:relative;
  /* background-image: url("/public/card_image1.png"); */
  background-size:cover; /* 배경 이미지가 컨테이너에 맞게 조정됨 */
	background-position:center;
  background-repeat: no-repeat; 
  transition: all 0.5s ease;
  
}
.card_container:hover{
  transform: scale(103%);
}
.card_title{
	padding:10px 20px;
	border-radius:15px;
	background-color:white;
  font-size: 1rem;
  font-weight: 500;
  color:var(--color-gray);
  margin-bottom: 20px;
}

/* 상담 게시판  */

.contact_main_title{
font-size: 1.7rem;;                                                        
   font-family: 'noto sans';                                                  
   font-weight: 400;                                                          
   text-align: center;                                                        
   border-bottom: var(--dotted-line);                                         
   color:var(--color-black);                                                  
   border-radius: 15px 15px 0  0;                                             
   padding-bottom: 10px;                                                      
   margin:0 20px;                          
   margin-bottom:50px;
}
.contact_container{
  padding:10px;
  border-radius:20px;
  box-shadow:2px 2px 10px #fff, 2px 2px 10px #babebc; 
  width:700px;
  margin:0 auto;
  //background-color: rgb(237, 237, 237);
  display:flex;
}
.contact_window{
   //background: #ebecf0;
}

.contact_window.list{

  flex-grow: 3;
  color:var(--color-gray);
  
}
form {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: start;
}

form input{
}
.contact_window.form{
  min-width: 300px;
  flex-grow:1;
}
.contact_window h3{
  font-size:1rem;
  font-weight:500;
  border-bottom:1px solid var(--color-line-gray);
}
.contact_form_title{
  text-align:center;
  padding:5px 0;
}
.contact_list_title{
  text-align:center;  
  padding:5px 0;
}
.contact_list_items{
  margin-top:10px;
}
.contact_list_tel{
  font-weight:500;
  margin:4px 0;
  padding-left:3px;
}

.contact_list_content{
  padding:3px;
  font-size:0.8rem;
  margin-bottom:7px;
  border-bottom:1px dotted var(--color-line-gray);
}
.contact_form_item{
  margin-top:10px;
}
.textarea_items{
  display:flex;
  align-items:start;
}
.contact_textarea{
  margin-right:0;
  width:100%;
  height:80px;
}
.contact_submit_btn{
  border-radius:15px;
  margin:10px auto;
  padding:10px;
  border:none;
}


/* Responsible screen size  */

@media screen and (max-width:600px){
  .section_first_img{
    width:200px;
  }
  .contact_container{
    flex-direction:column-reverse; 
    width:400px;
  }
  .contact_list_items{
    text-align:center;
  }
  .contact_list_title{
    margin-top:10px;
  }
  .contact_submit_btn{
    margin-right:0;
  }
}
 
@media  screen and (max-width:478px) {
  .navbar_item{
    padding:5px 5px;
  }

  .red_bubble{
    font-size: 0.5rem;
  }
  .custom-shape-divider-bottom-1721098286.divider{
    display: none;
  }
  .section_third_img_container{
    height:70px;
  }
 

}
@media screen and (max-width:337px) {
  .navbar_logo_subtext{
    font-size:0.5rem;
  }
}
@media screen and (max-width:900px) {
  .section_third_img_container{
    height:120px;
  }
 
}






/* Footer */
footer{

  margin-top:40px;
  /* background: var(--gradient-main); */
}
.footer_box{
  padding-top:50px;
  width: 90vw;
  height:100px;
  margin:0 auto;
  
  border-top:1px solid var(--color-gray-bright);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:var(--color-gray)
}
.footer_contact{
  letter-spacing: 3px;
  font-weight: 500;
  padding:10px 0;
}
.last_text{
  font-size: .9rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* event top box */

.event_container{
  z-index:1;
  width:200px;
  height:30px;
  position:absolute;
  left:50vw;
  transform:translateX(-100px);
  background-color: beige;
  border-radius:0 0 15px 15px;
  border:2px solid white;
  opacity:50%;
  display: flex;
  justify-content: center;
  align-items:center;
}
.event_text{
  /* border-radius: 30px;
  background-color: pink;
  border-bottom: 3px solid red;
  padding:3px 10px; */
  
}


