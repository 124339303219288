:root{
--color-white: #ffffff;
--color-black: #343434;
--color-blue: #479aff;
--color-orange: #f95700;
--color-red:rgb(247, 64, 58);
--color-gray: #313131;
--color-line-gray:rgb(173, 173, 173);
--color-gray-bright:rgb(235, 235, 235);
--gradient-main:linear-gradient(149deg, rgba(126,122,185,1) 0%, rgba(245,182,195,1) 100%);
--dotted-line:1px dotted rgba(188, 188, 188, 0.449);
--shadow-1:14px 13px 31px -10px rgba(0,0,0,0.75);
--shadow-2:5px 5px 13px 0px rgba(0, 0, 0, 0.07);

}

html{
  scroll-behavior: smooth;
}
.App{
    position:relative;
}    
    
body {
  margin: 0;
  font-family:'noto sans' 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box ;
}

h1, h2, h3, p, ul {
  margin:0;
}

ul{
  list-style: none;
  padding:0;
}
a{
  text-decoration: none;
  color:var(--color-white);
}
